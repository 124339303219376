import axios from 'axios'
import {defineStore} from 'pinia'
import {useAuthStore} from "@/stores/auth";


export const useApiSettingStore = defineStore({
    id: 'apiSettings',

    state: () => ({
        messageToDelete: '',
        typeObjectToDelete: '',
        confirmDelete: false,
        idToDelete: null,
        spinnerInfoPostalCodes: false,
        infoPostalCodes: null,
        suggestionSsn: null,
        apiSettings: null,
        error500: '',
        errorNetwork: false,
        overlayPopup: false,
        typePopup: '',
        genericCountList: 0,
        overlayModal: false,
        overlayFilter: false
    }),
    actions: {
        resetConfirmDelete() {
            this.idToDelete = null
            this.confirmDelete = false
            this.messageToDelete = ''
            this.typeObjectToDelete = ''
        },
        getApiSettings() {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.options('api/', config)
                    .then(res => {
                        this.apiSettings = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        getSuggestionSsn(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.post('api/utils/ssn/',payload, config)
                    .then(res => {
                        this.suggestionSsn = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        getPostalCodes(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                this.spinnerInfoPostalCodes = true
                axios.get('/api/utils/zip-code/?city=' + payload, config)
                    .then(res => {
                        this.infoPostalCodes = res.data.results.map(el => el.postalCode)
                        this.spinnerInfoPostalCodes = false
                        resolve(res)
                    })
                    .catch(err => {
                        this.spinnerInfoPostalCodes = false
                        reject(err)
                    })
                    .finally(() => {
                        this.spinnerInfoPostalCodes = false
                    })
            })
        },
        bulkDeleteFromList(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.post('api/' + payload.entity + '/bulk-destroy/', {ids:payload.list_ids}, config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        }
    },

    getters: {
        apiSettingsGetters: state => {
            return state.apiSettings
        },
        suggestionSsnGetters: state => {
            return state.suggestionSsn
        },
        error500Getters: state => {
            return state.error500
        },
        errorNetworkGetters: state => {
            return state.errorNetwork
        },
        overlayModalGetters: state => {
            return state.overlayModal
        },
        overlayPopupGetters: state => {
            return state.overlayPopup
        },
        typePopupGetters: state => {
            return state.typePopup
        },
        genericCountListGetters: state => {
            return state.genericCountList
        },
        overlayFilterGetters: state => {
            return state.overlayFilter
        },
        infoPostalCodesGetters: state => {
            return state.infoPostalCodes
        },
        spinnerInfoPostalCodesGetters: state => {
            return state.spinnerInfoPostalCodes
        },
        messageToDeleteGetters: state => {
            return state.messageToDelete
        },
        confirmDeleteGetters: state => {
            return state.confirmDelete
        },
        idToDeleteGetters: state => {
            return state.idToDelete
        },
        typeObjectToDeleteGetters: state => {
            return state.typeObjectToDelete
        }
    }
})


