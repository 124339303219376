<template>
  <div class="container-sidebar">
    <div class="container-logo">
      <img class="img-logo" src="../../assets/sidebar/logo.png" alt="">
    </div>
    <div class="container-links">
      <div :class="{'container_single_link': $route.path === '/'}" @click="goToPath('home')" v-if="$route.path === '/'"
           class="container-single-link background_active">
        <img src="../../assets/sidebar/home_active.svg" alt="Home">
        <span v-if="!switchText" class="text-link text-link-active">Home</span>
        <span v-else class="text-link text-link-active">Home</span>
      </div>
      <div :class="{'container_single_link': $route.path === '/'}" @click="goToPath('home')" v-else
           class="container-single-link">
        <img src="../../assets/sidebar/home.svg" alt="Home">
        <span v-if="!switchText" class="text-link text-link">Home</span>
        <span v-else class="text-link text-link">Home</span>
      </div>

      <div :class="{'container_single_link': $route.path.substring(0,14) === '/welcome-cards'}" @click="goToPath('welcome-cards')"
           v-if="$route.path.substring(0,14) === '/welcome-cards'"
           class="container-single-link background_active">
        <img src="../../assets/sidebar/file-text_active.svg" alt="Schede">
        <span v-if="!switchText" class="text-link text-link-active">Schede accoglienza</span>
        <span v-else class="text-link text-link-active">Schede a.</span>
      </div>
      <div :class="{'container_single_link': $route.path === '/welcome-cards'}" @click="goToPath('welcome-cards')"
           v-else
           class="container-single-link">
        <img src="../../assets/sidebar/file-text.svg" alt="Schede">
        <span v-if="!switchText" class="text-link text-link">Schede accoglienza</span>
        <span v-else class="text-link text-link">Schede a.</span>
      </div>

      <div v-if="storeAuth.userGetters.user_type !== 'o'">
        <div :class="{'container_single_link': $route.path.substring(0,8) === '/reports'}" @click="goToPath('reports')"
             v-if="$route.path.substring(0,8) === '/reports'"
             class="container-single-link background_active">
          <img src="../../assets/sidebar/clipboard_active.svg" alt="Report">
          <span v-if="!switchText" class="text-link text-link-active">Report</span>
          <span v-else class="text-link text-link-active">Report</span>
        </div>
        <div :class="{'container_single_link': $route.path === '/reports'}" @click="goToPath('reports')" v-else
             class="container-single-link">
          <img src="../../assets/sidebar/clipboard.svg" alt="Report">
          <span v-if="!switchText" class="text-link text-link">Report</span>
          <span v-else class="text-link text-link">Report</span>
        </div>
      </div>


      <div v-if="storeAuth.userGetters.user_type === 'sad'">
        <div :class="{'container_single_link': $route.path.substring(0,7) === '/admins'}" @click="goToPath('admins')"
             v-if="$route.path.substring(0,7) === '/admins'"
             class="container-single-link background_active">
          <img src="../../assets/sidebar/users_active.svg" alt="Admin">
          <span v-if="!switchText" class="text-link text-link-active">Gestione admin</span>
          <span v-else class="text-link text-link-active">Admin</span>
        </div>
        <div :class="{'container_single_link': $route.path === '/admins'}" @click="goToPath('admins')" v-else
             class="container-single-link">
          <img src="../../assets/sidebar/users.svg" alt="Admin">
          <span v-if="!switchText" class="text-link text-link">Gestione admin</span>
          <span v-else class="text-link text-link">Admin</span>
        </div>
      </div>


      <div v-if="storeAuth.userGetters.user_type !== 'o'">
        <div :class="{'container_single_link': $route.path.substring(0,10) === '/operators'}" @click="goToPath('operators')"
             v-if="$route.path.substring(0,10) === '/operators'"
             class="container-single-link background_active">
          <img src="../../assets/sidebar/users_active.svg" alt="Operatori">
          <span v-if="!switchText" class="text-link text-link-active">Gestione operatori</span>
          <span v-else class="text-link text-link-active">Operatori</span>
        </div>
        <div :class="{'container_single_link': $route.path === '/operators'}" @click="goToPath('operators')" v-else
             class="container-single-link">
          <img src="../../assets/sidebar/users.svg" alt="Operatori">
          <span v-if="!switchText" class="text-link text-link">Gestione operatori</span>
          <span v-else class="text-link text-link">Operatori</span>
        </div>
      </div>

      <div v-if="storeAuth.userGetters.user_type === 'sad'">
        <div :class="{'container_single_link': $route.path.substring(0,11) === '/structures'}" @click="goToPath('structures')"
             v-if="$route.path.substring(0,11) === '/structures'"
             class="container-single-link background_active">
          <img src="../../assets/sidebar/structure_active.svg" alt="Strutture">
          <span v-if="!switchText" class="text-link text-link-active">Gestione Strutture</span>
          <span v-else class="text-link text-link-active">Strutture</span>
        </div>
        <div :class="{'container_single_link': $route.path === '/structures'}" @click="goToPath('structures')" v-else
             class="container-single-link">
          <img src="../../assets/sidebar/structure.svg" alt="Strutture">
          <span v-if="!switchText" class="text-link text-link">Gestione Strutture</span>
          <span v-else class="text-link text-link">Strutture</span>
        </div>
      </div>


      <div v-if="storeAuth.userGetters.user_type !== 'o'">
        <div :class="{'container_single_link': $route.path.substring(0,9) === '/services'}" @click="goToPath('services')"
             v-if="$route.path.substring(0,9) === '/services'"
             class="container-single-link background_active">
          <img src="../../assets/sidebar/grid_active.svg" alt="Servizi">
          <span v-if="!switchText" class="text-link text-link-active">{{storeAuth.userGetters.user_type === 'ad' ? 'Lista' : 'Gestione'}} Servizi</span>
          <span v-else class="text-link text-link-active">Servizi</span>
        </div>
        <div :class="{'container_single_link': $route.path === '/services'}" @click="goToPath('services')" v-else
             class="container-single-link">
          <img src="../../assets/sidebar/grid.svg" alt="Servizi">
          <span v-if="!switchText" class="text-link text-link">{{storeAuth.userGetters.user_type === 'ad' ? 'Lista' : 'Gestione'}} Servizi</span>
          <span v-else class="text-link text-link">Servizi</span>
        </div>
      </div>

      <div v-if="storeAuth.userGetters.user_type !== 'o'">
        <div :class="{'container_single_link': $route.path.substring(0,6) === '/needs'}" @click="goToPath('needs')"
             v-if="$route.path.substring(0,6) === '/needs'"
             class="container-single-link background_active">
          <img src="../../assets/sidebar/grid_active.svg" alt="Servizi">
          <span v-if="!switchText" class="text-link text-link-active">{{storeAuth.userGetters.user_type === 'ad' ? 'Lista' : 'Gestione'}} Bisogni</span>
          <span v-else class="text-link text-link-active">Servizi</span>
        </div>
        <div :class="{'container_single_link': $route.path === '/needs'}" @click="goToPath('needs')" v-else
             class="container-single-link">
          <img src="../../assets/sidebar/grid.svg" alt="Servizi">
          <span v-if="!switchText" class="text-link text-link">{{storeAuth.userGetters.user_type === 'ad' ? 'Lista' : 'Gestione'}} Bisogni</span>
          <span v-else class="text-link text-link">Bisogni</span>
        </div>
      </div>


      <div v-if="storeAuth.userGetters.user_type !== 'o'">
        <div :class="{'container_single_link': $route.path.substring(0,10) === '/questions'}" @click="goToPath('questions')"
             v-if="$route.path.substring(0,10) === '/questions'"
             class="container-single-link background_active">
          <img src="../../assets/sidebar/help-circle_active.svg" alt="Domande Indirette">
          <span v-if="!switchText" class="text-link text-link-active">Domande Indirette</span>
          <span v-else class="text-link text-link-active">Domande</span>
        </div>
        <div :class="{'container_single_link': $route.path === '/questions'}" @click="goToPath('questions')" v-else
             class="container-single-link">
          <img src="../../assets/sidebar/help-circle.svg" alt="Domande Indirette">
          <span v-if="!switchText" class="text-link text-link">Domande Indirette</span>
          <span v-else class="text-link text-link">Domande</span>
        </div>
      </div>


      <div>
        <div :class="{'container_single_link': $route.path.substring(0,5) === '/faqs'}" @click="goToPath('faqs')"
             v-if="$route.path.substring(0,11) === '/faqs'"
             class="container-single-link background_active">
          <img width="16" src="../../assets/shared/faq_active.svg" alt="faqs">
          <span v-if="!switchText" class="text-link text-link-active">FAQ</span>
          <span v-else class="text-link text-link-active">FAQ</span>
        </div>
        <div :class="{'container_single_link': $route.path === '/faqs'}" @click="goToPath('faqs')" v-else
             class="container-single-link">
          <img width="16" src="../../assets/shared/faq.svg" alt="faqs">
          <span v-if="!switchText" class="text-link text-link">FAQ</span>
          <span v-else class="text-link text-link">FAQ</span>
        </div>
      </div>
      <div v-if="storeAuth.userGetters.user_type !== 'o'">
        <div :class="{'container_single_link': $route.path.substring(0,11) === '/chronology'}" @click="goToPath('chronology')"
             v-if="$route.path.substring(0,11) === '/chronology'"
             class="container-single-link background_active">
          <img src="../../assets/sidebar/notification_active.svg" alt="Cronologia">
          <span v-if="!switchText" class="text-link text-link-active">Cronologia</span>
          <span v-else class="text-link text-link-active">Cronologia</span>
        </div>
        <div :class="{'container_single_link': $route.path === '/chronology'}" @click="goToPath('chronology')" v-else
             class="container-single-link">
          <img src="../../assets/sidebar/notification.svg" alt="Cronologia">
          <span v-if="!switchText" class="text-link text-link">Cronologia</span>
          <span v-else class="text-link text-link">Cronologia</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {useApiSettingStore} from "@/stores/apiSettings";
import {useAuthStore} from "@/stores/auth";

export default {
  name: "ComponentSideBar",
  setup() {
    const storeApi = useApiSettingStore()
    const storeAuth = useAuthStore()
    return {
      storeApi,
      storeAuth
    }
  },
  data() {
    return {
      switchChecked: null,
      switchText: false
    }
  },
  created() {
  },
  mounted() {
    if (window.innerWidth < 991.98) {
      this.switchText = true
    } else {
      this.switchText = false
    }
    window.addEventListener('resize', (e) => {
      if (e.target.innerWidth < 991.98 || screen.orientation.angle === 90) {
        this.switchText = true
      } else {
        this.switchText = false
      }
    })
  },
  updated() {

  },
  methods: {
    goToPath(name) {
      this.$router.push({name: name}).catch(() => {
      })
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', () => {
    })
  },
  unmounted() {
    window.removeEventListener('resize', () => {
    })
  }
}
</script>

<style scoped>
.container_sidebar.container-sidebar {
  width: 10vw;
}

.img-logo {
  width: 80%;
}

.background_active.container-single-link {
  background: var(--color-f3f3f3);
}

.container-links {
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
  overflow: auto;
}

.container-links::-webkit-scrollbar {
  width: 0px;
}

.container-single-link {
  background: var(--color-f4f4f4);
  margin: 10px 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 15px 20px 15px 40px;
}

.text-link {
  font-style: normal;
  font-weight: var(--font-weight-regular);
  font-size: var(--x-small);
  /*line-height: 18px;*/
  color: var(--color-black);
  margin-left: 10px;
}

.text-link-active {
  font-style: normal;
  font-weight: var(--font-weight-bold);
  font-size: var(--x-small);
  /*line-height: 18px;*/
  color: var(--color-primary);
}

.container-logo {
  margin: 20px 0px 0px 0px;
  flex-direction: column;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}

.container-sidebar {
  width: 20vw;
  display: flex;
  position: fixed;
  left: 0;
  flex-direction: column;
  z-index: 2;
  background: var(--color-f4f4f4);
  height: 100vh;
  border-right: 10px solid var(--color-f3f3f3);
}


/*// Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {
  .container_single_link,.container-single-link{
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .text-link {
    display: none;
  }
}

/*// Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {

}

/*// Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {
  .container-single-link {
    padding: 10px 15px 10px 15px;
  }
}

/*// X-Large devices (large desktops, less than 1400px)*/
@media (max-width: 1399.98px) {

}


</style>