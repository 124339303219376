import axios from 'axios'
import {defineStore} from 'pinia'
import {useAuthStore} from "./auth";
import {useApiSettingStore} from "./apiSettings";


export const useReceptionCardStore = defineStore({
    id: 'receptionCards',

    state: () => ({
        receptionCards: null,
        allReceptionCards: null,
        spinnerReceptionCards: false,
        receptionCard: null,
        arrayReceptionCardsInfinity: [],
        optionsReceptionCard: null

    }),
    actions: {
        downloadReceptionCard(id) {
            return new Promise((resolve, reject) => {
                const config = {
                    responseType: 'blob',
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.get('api/reception-cards/' + id + '/template/', config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        downloadEmptyReceptionCard() {
            return new Promise((resolve, reject) => {
                const config = {
                    responseType: 'blob',
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.get('api/reception-cards/template', config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        getReceptionCards(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                let url = new URL(axios.defaults.baseURL + 'api/reception-cards/')

                this.spinnerReceptionCards = true
                if (payload.queryParams !== null) {
                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    axios.get(url.pathname + url.search, config)
                        .then(res => {
                            this.receptionCards = res.data
                            if (payload.queryParams.scroll) {
                                this.arrayReceptionCardsInfinity.push(...res.data.results)
                            } else {
                                this.arrayReceptionCardsInfinity = res.data.results
                            }
                            this.spinnerReceptionCards = false
                            resolve(res)
                        })
                        .catch(err => {
                            this.spinnerReceptionCards = false
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                } else {
                    axios.get(url.pathname, config)
                        .then(res => {
                            this.spinnerReceptionCards = false
                            this.receptionCards = res.data
                            let a = []
                            a.push(...res.data.results);
                            this.arrayReceptionCardsInfinity = a
                            resolve(res)
                        })
                        .catch(err => {
                            this.spinnerReceptionCards = false
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                }

            })
        },
        getAllReceptionCards(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                let url = new URL(axios.defaults.baseURL + 'api/reception-cards/')

                let params = new URLSearchParams()
                for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                    params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                }
                url.search = params
                axios.get(url.pathname + url.search, config)
                    .then(res => {
                        this.allReceptionCards = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })

            })
        },
        getReceptionCard(id) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.get('/api/reception-cards/' + id + '/', config)
                    .then(res => {
                        this.receptionCard = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        editReceptionCard(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.patch('/api/reception-cards/' + payload.id + '/', payload, config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        deleteReceptionCard(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                axios.delete('/api/reception-cards/' + payload + '/', config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        createReceptionCard(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.post('/api/reception-cards/', payload, config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        getOptionsReceptionCard() {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.options('api/reception-cards/', config)
                    .then(res => {
                        this.optionsReceptionCard = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        }
    },

    getters: {
        receptionCardsGetters: state => {
            return state.receptionCards
        },
        allReceptionCardsGetters: state => {
            return state.allReceptionCards
        },
        receptionCardGetters: state => {
            return state.receptionCard
        },
        optionsReceptionCardGetters: state => {
            return state.optionsReceptionCard
        },
        arrayReceptionCardsInfinityGetters: state => {
            return state.arrayReceptionCardsInfinity
        },
        spinnerReceptionCardsGetters: state => {
            return state.spinnerReceptionCards
        },

    }
})


