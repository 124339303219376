import axios from 'axios'
import {defineStore} from 'pinia'
import {useAuthStore} from "./auth";
import {useApiSettingStore} from "./apiSettings";
import {useUserStore} from "@/stores/users";


export const useOperatorStore = defineStore({
    id: 'operator',

    state: () => ({
        operators: null,
        allOperators: null,
        operator: null,
        arrayOperatorsInfinity: [],
        spinnerOperator: false,
        optionsOperator: null,
    }),
    actions: {
        getOperators(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                let url = new URL(axios.defaults.baseURL + 'api/operators/')

                this.spinnerOperator = true
                if (payload.queryParams !== null) {
                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params

                    axios.get(url.pathname + url.search, config)
                        .then(res => {
                            this.operators = res.data

                            if (payload.queryParams.scroll) {
                                this.arrayOperatorsInfinity.push(...res.data.results)
                            } else {
                                this.arrayOperatorsInfinity = res.data.results
                            }
                            this.spinnerOperator = false
                            resolve(res)
                        })
                        .catch(err => {
                            this.spinnerOperator = false
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                } else {
                    axios.get(url.pathname, config)
                        .then(res => {
                            this.spinnerOperator = false
                            this.operators = res.data
                            let a = []
                            a.push(...res.data.results);
                            this.arrayOperatorsInfinity = a
                            resolve(res)
                        })
                        .catch(err => {
                            this.spinnerOperator = false
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                }

            })
        },
        getAllOperators(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                let url = new URL(axios.defaults.baseURL + 'api/operators/')

                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    axios.get(url.pathname + url.search, config)
                        .then(res => {
                            this.allOperators = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })

            })
        },
        getOperator(id) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.get('/api/operators/' + id + '/', config)
                    .then(res => {
                        this.operator = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        deleteOperator(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                axios.delete('/api/users/' + payload + '/', config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        createOperator(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                axios.post('/api/users/', {
                    username: payload.username,
                    password: payload.password,
                    email: payload.email,
                    structure: payload.structure,
                    user_type: 'o',
                    confirmation: payload.confirmation
                }, config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        createOperatorDetail(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                axios.post('/api/users-details/', {
                    first_name: payload.first_name,
                    last_name: payload.last_name,
                    date_of_birth: payload.date_of_birth,
                    phone_number: payload.phone_number,
                    ssn: payload.ssn,
                    birth_place: payload.birth_place,
                    mobile_number: payload.mobile_number,
                    gender: payload.gender,
                    custom_user: payload.custom_user
                }, config)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        editOperator(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                useUserStore().editUser(payload)
                    .then(() => {
                        axios.patch('/api/users-details/' + payload.user_detail.id + '/', payload.user_detail, config)
                            .then(resp => {
                                resolve(resp)
                            })
                            .catch(err => {
                                if (err.response && err.response.status === 500) {
                                    useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                                }
                                reject(err)
                            })
                    })
                    .catch(() => {
                    })
            })
        },

        getOptionsOperator() {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.options('api/operators/', config)
                    .then(res => {
                        this.optionsOperator = res.data.actions.POST
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        }
    },

    getters: {
        operatorsGetters: state => {
            return state.operators
        },
        allOperatorsGetters: state => {
            return state.allOperators
        },
        operatorGetters: state => {
            return state.operator
        },
        optionsOperatorGetters: state => {
            return state.optionsOperator
        },
        arrayOperatorsInfinityGetters: state => {
            return state.arrayOperatorsInfinity
        },
        spinnerOperatorGetters: state => {
            return state.spinnerOperator
        },

    }
})


