import axios from 'axios'
import {defineStore} from 'pinia'
import {useAuthStore} from "./auth";
import {useApiSettingStore} from "./apiSettings";


export const useReportStore = defineStore({
    id: 'report',

    state: () => ({
        reports: null,
        report: null,
        arrayReportsInfinity: [],
        spinnerReport: false,
        optionsReport: null,
    }),
    actions: {
        getReports(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                let url = new URL(axios.defaults.baseURL + 'api/services/')

                this.spinnerReport = true
                if (payload.queryParams !== null) {
                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params

                    axios.get(url.pathname + url.search, config)
                        .then(res => {
                            this.reports = res.data

                            if (payload.queryParams.scroll) {
                                this.arrayReportsInfinity.push(...res.data.results)
                            } else {
                                this.arrayReportsInfinity = res.data.results
                            }
                            this.spinnerReport = false
                            resolve(res)
                        })
                        .catch(err => {
                            this.spinnerReport = false
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                } else {
                    axios.get(url.pathname, config)
                        .then(res => {
                            this.spinnerReport = false
                            this.reports = res.data
                            let a = []
                            a.push(...res.data.results);
                            this.arrayReportsInfinity = a
                            resolve(res)
                        })
                        .catch(err => {
                            this.spinnerReport = false
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                }

            })
        },
        getReport(id) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.get('/api/services/' + id + '/', config)
                    .then(res => {
                        this.report = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        deleteReport(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                axios.delete('/api/services/' + payload + '/', config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        createReport(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                axios.post('/api/services/', payload, config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        editReport(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                axios.patch('/api/services/' + payload.id + '/', payload, config)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },

        getOptionsReport() {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.options('api/services/', config)
                    .then(res => {
                        this.optionsReport = res.data.actions.POST
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        }
    },

    getters: {
        reportsGetters: state => {
            return state.reports
        },
        reportGetters: state => {
            return state.report
        },
        optionsReportGetters: state => {
            return state.optionsReport
        },
        arrayReportsInfinityGetters: state => {
            return state.arrayReportsInfinity
        },
        spinnerReportGetters: state => {
            return state.spinnerReport
        },

    }
})


