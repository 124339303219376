import axios from 'axios'
import {defineStore} from 'pinia'
import {useAuthStore} from "./auth";
import {useApiSettingStore} from "@/stores/apiSettings";


export const useDownloadStore = defineStore({
    id: 'download',

    state: () => ({
        selectedItems: [],
    }),
    actions: {
        getEntityDownload(payload) {
            return new Promise((resolve, reject) => {

                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                if (payload.entity === 'users'){
                    payload.queryParams['user_type__in'] = 'ad'
                }
                let selectedItems = useDownloadStore().selectedItemsGetters
                if (selectedItems.length > 0) {
                    payload.queryParams['id__in'] = selectedItems
                }
                let url = new URL(axios.defaults.baseURL + 'api/' + payload.entity + '/download/')
                if (payload.queryParams !== null) {
                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    axios.get(url.pathname + url.search, config)
                        .then(res => {
                            let contentDisposition = res.headers.get('content-disposition').split('filename=')
                            const blob = new Blob([res.data], {type: "text/csv"});
                            const link = document.createElement("a");
                            link.href = URL.createObjectURL(blob);

                            if (!contentDisposition) {
                                link.download =payload.entity + '-export-' + new Date().toISOString() + '.csv';
                            } else {
                                link.download = contentDisposition[1].substring(1, contentDisposition[1].length - 1);
                            }

                            link.click();
                            URL.revokeObjectURL(link.href);
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                } else {
                    axios.get(url.pathname, config)
                        .then(res => {
                            const blob = new Blob([res.data], {type: "text/csv"});
                            const link = document.createElement("a");
                            link.href = URL.createObjectURL(blob);
                            link.download = 'export.csv';
                            link.click();
                            URL.revokeObjectURL(link.href);
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                }

            })
        },

    },

    getters: {
        selectedItemsGetters: state => {
            return state.selectedItems
        }
    }
})


