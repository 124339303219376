import axios from 'axios'
import {defineStore} from 'pinia'
import {useAuthStore} from "./auth";
import {useApiSettingStore} from "./apiSettings";

export const useAdminStore = defineStore({
    id: 'admin',

    state: () => ({
        admins: null,
        allAdmins: null,
        admin: null,
        spinnerAdmin: false,
        arrayAdminsInfinity: [],
        optionsAdmin: null,
    }),
    actions: {
        getAdmins(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                let url = new URL(axios.defaults.baseURL + 'api/users/')

                this.spinnerAdmin = true
                if (payload.queryParams !== null) {
                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params

                    axios.get(url.pathname + url.search, config)
                        .then(res => {
                            this.admins = res.data
                            let array_filtered = []

                            res.data.forEach(el => {
                                if (el.user_type === 'ad') {
                                    array_filtered.push(el)
                                }
                            })
                            this.admins = array_filtered

                            if (payload.queryParams.scroll) {
                                this.arrayAdminsInfinity.push(...array_filtered)
                            } else {
                                this.arrayAdminsInfinity = array_filtered
                            }
                            useApiSettingStore().genericCountList = array_filtered.length
                            this.spinnerAdmin = false
                            resolve(res)
                        })
                        .catch(err => {
                            this.spinnerAdmin = false
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                } else {
                    axios.get(url.pathname, config)
                        .then(res => {
                            this.spinnerAdmin = false
                            this.admins = res.data
                            let array_filtered = []
                            res.data.forEach(el => {
                                if (el.user_type === 'ad') {
                                    array_filtered.push(el)
                                }
                            })
                            this.arrayAdminsInfinity = array_filtered
                            useApiSettingStore().genericCountList = array_filtered.length
                            resolve(res)
                        })
                        .catch(err => {
                            this.spinnerAdmin = false
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                }

            })
        },
        getAllAdmins(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                let url = new URL(axios.defaults.baseURL + 'api/users/')

                let params = new URLSearchParams()
                for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                    params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                }
                params.append('user_type__in', 'ad')
                url.search = params
                axios.get(url.pathname + url.search, config)
                    .then(res => {
                        this.allAdmins = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })

            })
        },
        getAdmin(id) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.get('/api/users/' + id + '/', config)
                    .then(res => {
                        this.admin = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        deleteAdmin(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                axios.delete('/api/users/' + payload + '/', config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        createAdmin(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                axios.post('/api/users/', {
                    username: payload.username,
                    password: payload.password,
                    email: payload.email,
                    user_type: 'ad',
                    structure: payload.structure,
                    confirmation: payload.confirmation
                }, config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        createAdminDetail(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                axios.post('/api/users-details/', {
                    first_name: payload.first_name,
                    last_name: payload.last_name,
                    date_of_birth: payload.date_of_birth,
                    phone_number: payload.phone_number,
                    ssn: payload.ssn,
                    birth_place: payload.birth_place,
                    mobile_number: payload.mobile_number,
                    gender: payload.gender,
                    custom_user: payload.custom_user
                }, config)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        getOptionsAdmin() {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.options('api/admins/', config)
                    .then(res => {
                        this.optionsAdmin = res.data.actions.POST
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        }
    },

    getters: {
        adminsGetters: state => {
            return state.admins
        },
        allAdminsGetters: state => {
            return state.allAdmins
        },
        adminGetters: state => {
            return state.admin
        },
        arrayAdminsInfinityGetters: state => {
            return state.arrayAdminsInfinity
        },
        optionsAdminGetters: state => {
            return state.optionsAdmin
        },
        spinnerAdminGetters: state => {
            return state.spinnerAdmin
        }

    }
})


