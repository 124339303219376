<template>
  <div class="container-view">
    <ComponentSideBar v-if="storeAuth.isAuthenticated"></ComponentSideBar>
    <div class="container-content-router-view">
      <ComponentHeaderBar v-if="storeAuth.isAuthenticated"></ComponentHeaderBar>
      <router-view></router-view>
    </div>
    <div v-if="store.overlayModalGetters || store.overlayPopupGetters" class="overlay"></div>
    <b-modal @close="resetStateStoreModal" @hide="resetStateStoreModal" title="Conferma eliminazione"
             :hide-footer="true"
             v-model="showModal">
      <div class="container-content-modal">
        <span v-if="store.messageToDeleteGetters" v-html="store.messageToDeleteGetters"></span>
      </div>
      <div class="container-buttons">
        <button @click="resetStateStoreModal" class="btn btn-cancel-custom">Annulla</button>
        <button @click="deleteObject" class="btn btn-danger-custom">
          <span>Elimina definitivamente</span>
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>

import ComponentSideBar from "@/components/sidebar/ComponentSideBar";
import {useAuthStore} from "@/stores/auth";
import ComponentHeaderBar from "@/components/header/ComponentHeaderBar";
import {useApiSettingStore} from "@/stores/apiSettings";
import {useUserStore} from "@/stores/users";
import {calculateMargin} from "@/main";
import axios from "axios";

export default {
  name: 'App',
  components: {ComponentHeaderBar, ComponentSideBar},
  setup() {
    const store = useApiSettingStore()
    const storeAuth = useAuthStore()
    const storeUser = useUserStore()
    return {
      storeAuth,
      storeUser,
      store
    }
  },
  data() {
    return {
      dropdownState: false,
      showModal: false
    }
  },
  created() {
    this.resetStateStoreModal()
    useApiSettingStore().overlayFilter = false
    useApiSettingStore().overlayModal = false
    useApiSettingStore().overlayPopup = false
    this.emitter.on('checkStateDropdownMenu', (bool) => {
      this.dropdownState = bool
    })
  },
  computed: {
    confirmDelete() {
      return useApiSettingStore().confirmDeleteGetters
    },
  },
  watch: {
    confirmDelete: {
      handler: function (value) {
        this.showModal = value
      }, deep: true
    }
  },
  mounted() {
    // this.download()
    if (useAuthStore().isAuthenticated) {
      useApiSettingStore().getApiSettings()
          .then(() => {
          })
          .catch(() => {
          })
    }

    calculateMargin()
    if (window.innerWidth < window.innerHeight) {
      this.changeVariables()
    } else {
      this.resetVariables()
    }
    window.addEventListener('resize', (e) => {
      if (e.target.innerWidth < e.target.innerHeight) {
        this.changeVariables()
      } else {
        this.resetVariables()
      }
      calculateMargin()
    })
    window.addEventListener('click', (e) => {
      let container_dropdown_menu_header_bar = document.getElementsByClassName('container-dropdown-menu-header-bar')[0]
      let container_name = document.getElementsByClassName('container-name')[0]
      if (container_dropdown_menu_header_bar && container_name) {
        if (!container_dropdown_menu_header_bar.contains(e.target) && !container_name.contains(e.target)) {
          this.dropdownState = false
          if (!this.dropdownState) {
            this.emitter.emit('closeDropdownMenu')
          }
        }
      }
    })
  },
  methods: {
    download() {
      const config = {
        responseType: 'blob',
        headers: {
          Authorization: 'Token ' + useAuthStore().userGetters.token
        }
      }

      axios.get('api/reception-cards/10/template/', config)
          .then(res => {
            console.log(res)
            const url = URL.createObjectURL(res.data);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'static but for now.pdf'; // Replace with your desired file name
            link.click();
            console.log('SEI BELLO')
          })
          .catch(() => {
            console.log('FAI SCHIFO')
          })
    },
    deleteObject() {
      if (useApiSettingStore().typeObjectToDeleteGetters === 'operator') {
        this.emitter.emit('canDeleteOperator')
      } else if (useApiSettingStore().typeObjectToDeleteGetters === 'admin') {
        this.emitter.emit('canDeleteAdmin')
      } else if (useApiSettingStore().typeObjectToDeleteGetters === 'caregiver') {
        this.emitter.emit('canDeleteCaregiver')
      } else if (useApiSettingStore().typeObjectToDeleteGetters === 'receptionCard') {
        this.emitter.emit('canDeleteReceptionCard')
      } else if (useApiSettingStore().typeObjectToDeleteGetters === 'structure') {
        this.emitter.emit('canDeleteStructure')
      } else if (useApiSettingStore().typeObjectToDeleteGetters === 'service') {
        this.emitter.emit('canDeleteService')
      } else if (useApiSettingStore().typeObjectToDeleteGetters === 'survey') {
        this.emitter.emit('canDeleteSurvey')
      } else if (useApiSettingStore().typeObjectToDeleteGetters === 'need') {
        this.emitter.emit('canDeleteNeed')
      }
    },
    resetStateStoreModal() {
      this.showModal = false
      useApiSettingStore().messageToDelete = ''
      useApiSettingStore().confirmDelete = false
      useApiSettingStore().objectToDelete = null
    },
    changeVariables() {
      document.documentElement.style.setProperty('--x-small', '1.5vmax');
      document.documentElement.style.setProperty('--small', '1.7vmax');
      document.documentElement.style.setProperty('--medium', '2.5vmax');
      document.documentElement.style.setProperty('--xx-large', '7vmax');
    },
    resetVariables() {

      document.documentElement.style.setProperty('--x-small', '1.5vmin');
      document.documentElement.style.setProperty('--small', '1.7vmin');
      document.documentElement.style.setProperty('--medium', '2.5vmin');
      document.documentElement.style.setProperty('--xx-large', '7vmin');
    }
  },
  updated() {
    calculateMargin()
  },
  beforeUnmount() {
    window.removeEventListener('resize', () => {
    })
    window.removeEventListener('click', calculateMargin)
    this.emitter.off('checkStateDropdownMenu', () => {
    })
  },
  unmounted() {
    window.removeEventListener('resize', () => {
    })
    window.removeEventListener('click', calculateMargin)
  }
}
</script>

<style>
.container-buttons {
  display: flex;
  align-items: center;
  margin: 10px 0px;
}

.btn-danger-custom {
  margin-left: 10px;
}

.container-view {
  display: flex;
  background: #F3F3F3;
  min-height: 100vh;
}

.container-content-router-view {
  display: flex;
  flex-direction: column;
  position: relative;
  background: var(--color-f4f4f4);
  width: 100%;
}

#app {
  min-height: 100vh;
}
</style>
