import axios from 'axios'
import {defineStore} from 'pinia'
import {useAuthStore} from "./auth";
import {useApiSettingStore} from "./apiSettings";


export const useStructureStore = defineStore({
    id: 'structures',

    state: () => ({
        structures: null,
        allStructures: null,
        spinnerStructures: false,
        structure: null,
        arrayStructuresInfinity: [],
        optionsStructure: null,
    }),
    actions: {
        getStructures(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                let url = new URL(axios.defaults.baseURL + 'api/structures/')

                this.spinnerStructures = true
                if (payload.queryParams !== null) {
                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    axios.get(url.pathname + url.search, config)
                        .then(res => {
                            this.structures = res.data
                            if (payload.queryParams.scroll) {
                                this.arrayStructuresInfinity.push(...res.data.results)
                            } else {
                                this.arrayStructuresInfinity = res.data.results
                            }
                            this.spinnerStructures = false
                            resolve(res)
                        })
                        .catch(err => {
                            this.spinnerStructures = false
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                } else {
                    axios.get(url.pathname, config)
                        .then(res => {
                            this.spinnerStructures = false
                            this.structures = res.data
                            let a = []
                            a.push(...res.data.results);
                            this.arrayStructuresInfinity = a
                            resolve(res)
                        })
                        .catch(err => {
                            this.spinnerStructures = false
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                }

            })
        },
        getAllStructures(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                let url = new URL(axios.defaults.baseURL + 'api/structures/')

                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    axios.get(url.pathname + url.search, config)
                        .then(res => {
                            this.allStructures = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })

            })
        },
        getStructure(id) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.get('/api/structures/' + id + '/', config)
                    .then(res => {
                        this.structure = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        editStructure(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.patch('/api/structures/' + payload.id + '/',payload, config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        deleteStructure(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                axios.delete('/api/structures/' + payload + '/', config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        createStructure(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.post('/api/structures/', payload, config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        getOptionsStructure() {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.options('api/structures/', config)
                    .then(res => {
                        this.optionsStructure = res.data.actions.POST
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        }
    },

    getters: {
        structuresGetters: state => {
            return state.structures
        },
        allStructuresGetters: state => {
            return state.allStructures
        },
        structureGetters: state => {
            return state.structure
        },
        optionsStructureGetters: state => {
            return state.optionsStructure
        },
        arrayStructuresInfinityGetters: state => {
            return state.arrayStructuresInfinity
        },
        spinnerStructuresGetters: state => {
            return state.spinnerStructures
        },

    }
})


